import { GraphQLClient } from 'graphql-request'
import { create } from 'zustand'

const url = process.env.NEXT_PUBLIC_API_URL

export const adminClient = new GraphQLClient(`${url}/graphql`)

export const useAdminToken = create<{
  token: string | null
  setToken: (token: string | null) => void
}>((set, get) => ({
  token: null,
  setToken: (token) => {
    set({ token })
  },
}))
