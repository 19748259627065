'use client'
import * as React from 'react'
import { useEffect } from 'react'
import { useSession } from 'next-auth/react'
import { useFetcher } from 'utils'
import { useRouter } from 'next/navigation'

export function LoginManagement({ children }) {
  const { data: session, status } = useSession()
  const router = useRouter()
  const { setAuthToken } = useFetcher((state) => {
    return {
      authToken: state.authToken,
      setAuthToken: state.setAuthToken,
    }
  })

  useEffect(() => {
    if (session?.user?.token && session?.user?.id) {
      setAuthToken(session.user.token)
      router.push('/')
    }
  }, [session, setAuthToken])

  return <>{children}</>
}
