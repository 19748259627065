import { FetcherError } from './errors'

export function getError(errors: any[], status: number) {
  errors = errors ?? [{ message: 'Failed to fetch API' }]
  status =
    errors && errors.length
      ? errors[0]?.extensions?.response?.statusCode
      : status

  return new FetcherError({ errors, status })
}

export async function getAsyncError(res: Response) {
  const data = await res.json()
  return getError([{ message: data.message }], data.statusCode)
}

export const handleFetchResponse = async (
  res: Response,
  errorHandler?: (errors: any) => void
) => {
  if (res.ok) {
    const { data, errors } = await res.json()
    if (errors && errors.length) {
      console.log(data, errors)
      if (errorHandler) errorHandler(errors)
      throw getError(errors, res.status)
    }

    return data
  }

  throw await getAsyncError(res)
}
