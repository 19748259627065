import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { handleFetchResponse } from './handle-fetch-response'

type Fetcher<T = any, B = any> = (options: FetcherOptions<B>) => T | Promise<T>

export type FetcherOptions<Body = any> = {
  query?: string
  variables?: any
  authToken?: string
  custom?: any
  url?: string
  errorHandler?: (error?: any) => void
}

export const fetcher: Fetcher = async ({
  variables,
  query,
  authToken,
  custom,
  url,
  errorHandler,
}) => {
  return handleFetchResponse(
    await fetch(url!, {
      method: 'POST',
      body: JSON.stringify({ query, variables }),
      ...(!custom && {
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken ? `Bearer ${authToken}` : '',
        },
      }),
      ...custom,
    }),
    errorHandler
  )
}

const persistData =
  typeof window !== 'undefined' && window.localStorage.getItem('fetch-storage')
    ? JSON.parse(window.localStorage.getItem('fetch-storage')!).state
    : ''

export const authTokenSelector = (state) => state.authToken
export const setAuthTokenSelector = (state) => state.setAuthToken
export const clearTokenSelector = (state) => state.clearToken
export const fetcherSelector = (state) => state.fetcher
export const authFetcherSelector = (state) => state.authFetcher
export const urlSelector = (state) => state.url
export const setUrlSelector = (state) => state.setUrl

type FetcherState = {
  fetcher: Fetcher
  authFetcher: Fetcher
  isFetching: boolean
  url: string | null
  authToken: string | null
  setUrl: (url: string | null) => void
  setAuthToken: (authToken: string | null) => void
  clearToken: () => void
}

export const useFetcher = create<FetcherState>()(
  persist(
    (set, get) => ({
      url: persistData.url || null,
      authToken: persistData.authToken || null,
      isFetching: false,
      setUrl: (url: string | null) => {
        set({ url })
      },
      setAuthToken: (authToken: string | null) => {
        set({ authToken })
      },
      clearToken: () => set({ authToken: null }),
      fetcher: (data): Fetcher => {
        const { url } = get() as any
        if (!url && !data.url)
          return Promise.reject(
            new Error('useFetcherError: No url provided')
          ) as any

        return fetcher({ url, ...data })
      },
      authFetcher: async (data) => {
        const { url, authToken } = get() as any

        if (!url && !data.url)
          return Promise.reject(
            new Error('useFetcherError: No url provided')
          ) as any

        if (!authToken)
          return Promise.reject(new Error('useFetcherError: No token')) as any

        set({ isFetching: true })
        try {
          const res = await fetcher({
            url,
            ...data,
            authToken,
          })
          set({ isFetching: false })
          return res
        } catch (ex) {
          set({ isFetching: false })
          throw ex
        }
      },
    }),
    {
      name: 'fetch-storage',
      storage: createJSONStorage(() => localStorage),
    }
  )
)
