'use client'
import { useEffect } from 'react'

export function useDisableRightClick() {
  useEffect(() => {
    if (typeof window === 'undefined') return
    window.addEventListener('contextmenu', (e) => e.preventDefault())
    return () =>
      window.removeEventListener('contextmenu', (e) => e.preventDefault())
  }, [])
}
