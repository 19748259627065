'use client'
import { createContext, ReactNode, useContext, useState } from 'react'
import { LoginForm } from '../types/login-user-form'
import { UserAgent } from '../types/user-types'

type PhoneVerify = {
  phone: string
  isPhoneVerify: boolean
}
type LoginData = LoginForm & Partial<PhoneVerify>

type LoginState = {
  pem: string
  userAgent: UserAgent
  loginData: LoginData
  setLoginData: (loginData: LoginData) => void
}

const LoginUserContext = createContext<LoginState>({
  pem: null,
  userAgent: null,
  loginData: {
    email: '',
    password: '',
    phone: '',
    isPhoneVerify: false,
  },
  setLoginData: (loginData: LoginData) => {},
})

export const useLoginManagement = <T,>(selector: (state: LoginState) => T): T =>
  selector(useContext(LoginUserContext))

export function LoginProvider({
  children,
  pem,
  userAgent,
}: {
  children: ReactNode
  pem: string
  userAgent: UserAgent
}) {
  const [loginData, setLoginData] = useState<LoginData>({
    email: '',
    password: '',
    phone: '',
    isPhoneVerify: false,
  })
  const [phoneVerification, setPhoneVerification] = useState<PhoneVerify>(null)

  return (
    <LoginUserContext.Provider
      value={{
        pem,
        userAgent,
        loginData,
        setLoginData,
        phoneVerification,
        setPhoneVerification,
      }}>
      {children}
    </LoginUserContext.Provider>
  )
}
