export const swrConfig = {
  revalidateOnFocus: false,
  onErrorRetry: (err, key, config, revalidate, { retryCount }) => {
    if (err.message.includes('No url') && retryCount < 3) {
      setTimeout(() => revalidate({ retryCount }), 100)
    }
    if (err.message.includes('No token')) {
      setTimeout(() => revalidate({ retryCount }), 100)
    }
  },
}
