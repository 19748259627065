export class ApiError extends Error {
  get status() {
    return this._status
  }

  constructor(
    private _status: number,
    public message: string
  ) {
    super(message)
  }
}
